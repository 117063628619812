<template xmlns="http://www.w3.org/1999/html">
	<div>
		<el-row>
			<el-col>
				<el-form :inline='true'>
					<el-form-item label="关键字:">
						<el-input v-model='filters.keyWord' placeholder="请输入名称" clearable @clear='handleClear'></el-input>
					</el-form-item>
					<el-form-item label="缴费方式:">
						<el-select v-model="filters.protocolPayMethod" style="width: 120px" clearable placeholder="请选择">
							<el-option
									v-for="item in options"
									:key="item.value"
									:label="item.name"
									:value="item.value"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
					</el-form-item>
					<el-form-item>
						<el-button type="text" @click="handleDownload">送培学员名单模版下载</el-button>
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<el-table
				:data="tableData"
				border
				v-loading="listLoading"
				@current-change="selectCurrentRow"
				highlight-current-row
				style="width: 100%;margin-bottom: 20px"
		>
			<el-table-column prop="Code" type="index"  label="序号" align="center" :index='indexMethod' width="80"></el-table-column>
			<el-table-column prop="ProtocolCode" label="协议编号" align="center" width="100"></el-table-column>
			<el-table-column prop="UnitName" label="单位客户名称" align="center" width="120"></el-table-column>
			<el-table-column prop="ContactPerson" label="客户联系人" align="center" width=""></el-table-column>
			<el-table-column prop="ContactPersonPhoneNumber" label="联系人手机号" align="center" width=""></el-table-column>
			<el-table-column prop="SettingStaffNum" label="协议送配数" align="center" width=""></el-table-column>
			<el-table-column prop="ActualStaffNum" label="实际送培数" align="center" width="240"></el-table-column>
			<el-table-column prop="ProtocolPayMethod" label="缴费方式" align="center" width="120">
				<template slot-scope="scope">
					<el-tag v-if="scope.row.ProtocolPayMethod === 1" type="">先付款后送培</el-tag>
					<el-tag v-else type="success">单位协议缴费</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="SettleMethod" label="结算方式" align="center" width="120">
				<template slot-scope="scope">
					<el-tag v-if="scope.row.SettleMethod === 1" type="">取证支付</el-tag>
					<el-tag v-else type="success">按期支付</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="ProtocolEffectDate" label="付款义务起始日" align="center" width="120"></el-table-column>
			<el-table-column prop="ProtocolInCharge" label="催收责任人" align="center" width="120"></el-table-column>
			<el-table-column prop="CreateTime" label="创建时间" align="center" width=""></el-table-column>
			<el-table-column prop="Name" label="操作" align="center" width="">
				<template slot-scope="scope">
					<el-button type="text" @click="preview(scope.row)">学员列表</el-button>
				</template>
			</el-table-column>
		
		</el-table>
		<!--底部工具条-->
		<el-col :span="24" class="pageBar" style="margin:20px 0">
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pages.pageIndex"
					:page-sizes="pages.pageArr"
					:page-size="pages.pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="pages.dataCount">
			</el-pagination>
		</el-col>
<!--	新建单位客户协议缴费	-->
		<el-dialog
				:title="isEdit?'编辑单位协议' : '新建单位协议'"
				:visible.sync="addOrEditDialog"
				v-model="addOrEditDialog"
				:close-on-click-modal="false"
				width="60%"
		>
			<el-form label-width="160px" :model="formData" ref="formData" :rules="formDataRules" label-position="right">
				<div style="display: flex;justify-content: space-between">
					<el-form-item label="单位名称：" style="width: 50%" prop="socialUnitName">
<!--						<el-input v-model='formData.socialUnitName' style="width: 80%" placeholder="请输入名称" clearable @clear='handleClear'></el-input>-->
						<el-select
								v-model="formData.socialUnitName"
								filterable
								remote
								style="width: 80%"
								reserve-keyword
								placeholder="请输入关键词"
								:remote-method="remoteMethod"
								@change="socialChangeValue"
								:loading="loading">
							<el-option
									v-for="(item, index) in optionsSocial"
									:key="index"
									:label="item.UnitName"
									:value="index">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="社会统一信用代码：" style="width: 50%" prop="socialCreditNumber">
						<el-input v-model='formData.socialCreditNumber' style="width: 80%" placeholder="请输入社会统一信用代码" clearable @clear='handleClear'></el-input>
					</el-form-item>
				</div>
				<div style="display: flex;justify-content: space-between">
					<el-form-item label="开户银行：" style="width: 50%" prop="bankName">
						<el-input v-model='formData.bankName' style="width: 80%" placeholder="请输入开户银行" clearable @clear='handleClear'></el-input>
					</el-form-item>
					<el-form-item label="银行账号：" style="width: 50%"  prop="bankCardNumber">
						<el-input v-model='formData.bankCardNumber' style="width: 80%" placeholder="请输入银行账号" clearable @clear='handleClear'></el-input>
					</el-form-item>
				</div>
				<div style="display: flex;justify-content: space-between">
					<el-form-item label="注册地址：" style="width: 50%" prop="address">
						<el-input v-model='formData.address' style="width: 80%" placeholder="请输入注册地址" clearable @clear='handleClear'></el-input>
					</el-form-item>
					<el-form-item label="电话：" style="width: 50%" prop="phoneNumber">
						<el-input v-model='formData.phoneNumber' style="width: 80%" placeholder="请输入电话" clearable @clear='handleClear'></el-input>
					</el-form-item>
				</div>
				<div style="display: flex;justify-content: space-between">
					<el-form-item label="客户联系人：" style="width: 50%" prop="contactPerson">
						<el-input v-model='formData.contactPerson' style="width: 80%" placeholder="请输入客户联系人" clearable @clear='handleClear'></el-input>
					</el-form-item>
					<el-form-item label="联系人手机：" style="width: 50%" prop="contactPersonPhoneNumber">
						<el-input v-model='formData.contactPersonPhoneNumber' style="width: 80%" placeholder="请输入联系人手机" clearable @clear='handleClear'></el-input>
					</el-form-item>
				</div>
				<hr></hr>
				<div style="display: flex;justify-content: space-between">
					<el-form-item label="协议缴费方式：" style="width: 50%" >
<!--						<el-input v-model='formData.protocolPayMethod' style="width: 80%" placeholder="请输入名称" clearable @clear='handleClear'></el-input>-->
						<el-select v-model='formData.protocolPayMethod' style="width: 160px" placeholder="请选择" clearable @clear='handleClear'>
							<el-option
									v-for="item in settleMethod"
									:key="item.value"
									:label="item.label"
									:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
				<div style="display: flex;justify-content: space-between">
					<el-form-item label="上传协议文本：" style="width: 50%" required>
<!--						<el-input v-model='formData.protocolUrl' style="width: 80%" placeholder="请输入名称" clearable @clear='handleClear'></el-input>-->
						<div style="display: flex;justify-content: space-between;width: 80%">
							<el-upload
									:action="uploadImagesUrl"
									:headers="headers"
									ref="upload"
									:before-upload="beforeUpload"
									:on-success="handle_success1"
									:show-file-list="false"
									list-type=""
									:file-list="fileList"
							>
								<el-button type="primary">上传协议文本 </el-button>
							</el-upload>
							<el-button type="primary" @click="handleUploadView">查看协议文本</el-button>
						</div>
					</el-form-item>
					<el-form-item label="上传送培学员名单：" style="width: 50%" required>

						<div style="display: flex;justify-content: space-between;width: 100%">
							<input
									type="file"
									@change="handleFileUpload"
									style="width: 80%"
									ref="fileInput"
							/>
							<el-button type="primary" @click="handleClickView">查看送培人员名单</el-button>
						</div>
					</el-form-item>
				</div>
				<div style="display: flex;justify-content: space-between">
					<el-form-item label="结算方式：" style="width: 50%" prop="settleMethod">
						<div style="display: flex;justify-content: space-between">
							<el-select v-model='formData.settleMethod' style="width: 160px" placeholder="请选择" clearable @clear='handleClear'>
								<el-option
										v-for="item in settleMethod"
										:key="item.value"
										:label="item.label"
										:value="item.value">
								</el-option>
							</el-select>
							<div style="display: flex;justify-content: center">
								每
								<el-select v-model='formData.settlePeriod' style="width:120px" placeholder="请选择月" clearable @clear='handleClear'>
									<el-option
											v-for="item in settlePeriod"
											:key="item.value"
											:label="item.label"
											:value="item.value">
									</el-option>
								</el-select>
								结算一次
							</div>
						</div>
					</el-form-item>
					<el-form-item label="付款方式：" style="width: 50%" prop="payMethod">
						<el-select v-model='formData.payMethod' style="width: 80%" placeholder="请选择" clearable @clear='handleClear'>
							<el-option
									v-for="item in payMethod"
									:key="item.value"
									:label="item.label"
									:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
				<div style="display: flex;justify-content: space-between">
					<el-form-item label="合同生效时间：" style="width: 50%" prop="protocolEffectDate">
						<el-date-picker
								v-model="formData.protocolEffectDate"
								type="date"
								format="yyyy 年 MM 月 dd 日"
								value-format="yyyy-MM-dd"
								placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="约定付款时间：" style="width: 50%" prop="planPayDate">
						<el-date-picker
								v-model="formData.planPayDate"
								type="date"
								format="yyyy 年 MM 月 dd 日"
								value-format="yyyy-MM-dd"
								placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</div>
				<div style="display: flex;justify-content: space-between">
					<el-form-item label="催收责任人：" style="width: 50%" prop="protocolInCharge">
						<el-input v-model='formData.protocolInCharge' style="width: 80%" placeholder="请输入催收责任人" clearable @clear='handleClear'></el-input>
					</el-form-item>
				</div>
				<hr></hr>
				<div style="padding-top: 25px">
					<table   border="'0.1"
					        style="border-collapse:collapse;text-align: center;margin: 0 auto;width: 100%;"
					        bordercolor="#ccc">
						<tr style="height: 45px">
							<th>培训项目</th>
							<th>协议单价（元）</th>
							<th>协议人数</th>
							<th>招生提成/人（元）</th>
						</tr>
						<tr  style="height: 45px" v-for="(item,index) in form" :key="item.Id">
							<td>{{item.Name}}</td>
							<td>
								<el-input type="" v-model="formList[item.Id].protocolPrice" placeholder="请输入价格" :disabled="false"></el-input>
							</td>
							<td>
								<el-input type="" v-model="formList[item.Id].protocolPersonNum" placeholder="请输入人数" :disabled="false"></el-input>
							</td>
							<td>
								<el-input  type="" v-model="formList[item.Id].protocolAllowance" placeholder="请输入价格" :disabled="false"></el-input></td>
						</tr>
					</table>
				</div>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="addOrEditDialog = false">取 消</el-button>
				<el-button type="primary" @click="confirmAdd">确 定</el-button>
			</div>
		</el-dialog>
<!--	学员列表	-->
		<el-dialog
				title="学员列表"
				:visible.sync="previewDialog"
				v-model="previewDialog"
				:close-on-click-modal="false"
				width="70%"
		>
			<template>
				单位名称：{{needData?needData.UnitName:''}}
				&nbsp;&nbsp;&nbsp;&nbsp;
				协议编号：{{needData?needData.ProtocolCode:''}}
			</template>
			<el-form :inline="true" label-width="" style="margin-top: 20px">
				<el-form-item label="关键字：">
					<el-input v-model="keywordStudy" style="width: 220px" placeholder="请输入学员姓名" clearable @clear='handleClear'></el-input>
				</el-form-item>
				<el-form-item label="">
					<el-button type="primary" @click="getListDataView">查询</el-button>
					<el-button type="primary" @click="handleAddView">添加学员</el-button>
					<el-button type="primary" @click="handleEditView">关联已报名学员</el-button>
					<el-button type="primary" @click="handleExport">导入送培学员名单</el-button>
					<el-button type="text" @click="handleDownload">送培学员名单模版下载</el-button>
				</el-form-item>
			</el-form>
			<el-table :data="tableDataView" v-loading="listViewLoading" border highlight-current-row stripe  style="width: 100%;margin-bottom: 20px">
				<el-table-column prop="Name" label="学员姓名" width="" center></el-table-column>
				<el-table-column prop="PhoneNumber" label="手机号" width=""></el-table-column>
				<el-table-column prop="StationName" label="培训项目" width=""></el-table-column>
				<el-table-column prop="ProtocolPrice" label="协议单价（元）" width=""></el-table-column>
				<el-table-column prop="CreateTime" label="报名时间" width=""></el-table-column>
				<el-table-column prop="" label="操作" width="">
					<template slot-scope="scope">
						<el-button type="text" @click="handleEditClick(scope.row)">变更学员</el-button>
						<el-button type="text" @click="handleCancleClick(scope.row)">解绑</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="width: 100%">
				<el-col  class="" style="width:220px;margin: 0 auto;">
					<el-pagination
							@size-change="handleSizeChangeStudy"
							@current-change="handleCurrentChangeStudy"
							:current-page="pagesView.pageIndex"
							:page-sizes="pagesView.pageArr"
							:page-size="pagesView.pageSize"
							layout="total, sizes, prev, pager, next, jumper"
							:total="pagesView.dataCount">
					</el-pagination>
				</el-col>
			</div>
			<div slot="footer" class="dialog-footer">
			</div>
		</el-dialog>
<!--	添加学员	-->
		<el-dialog
				:title="isAdd?'添加学员':'变更学员'"
				:visible.sync="addStudentDialog"
				v-model="addStudentDialog"
				:close-on-click-modal="false"
				width="40%"
		>
			<el-form label-width="150px" style="margin-top: 20px" :model="formViewData" ref="formViewData" :rules="formViewDataRules">
				<el-form-item label="手机号：" prop="phoneNumber">
					<el-input v-model="formViewData.phoneNumber" style="width: 90%" placeholder="请输入手机号" clearable @clear='handleClear'></el-input>
				</el-form-item>
				<el-form-item label="学员姓名：" prop="name">
					<el-input v-model="formViewData.name"  style="width: 90%" placeholder="请输入学员姓名" clearable @clear='handleClear'></el-input>
				</el-form-item>
<!--				<el-form-item label="身份证号：" prop="cardNo">-->
<!--					<el-input v-model="formViewData.cardNo"  style="width: 90%" placeholder="请输入身份证号" clearable @clear='handleClear'></el-input>-->
<!--				</el-form-item>-->
				<el-form-item label="单位名称：" prop="socialUnitName">
<!--					<el-input v-model="formViewData.studentName"  style="width: 90%" placeholder="请输入单位名称" clearable @clear='handleClear'></el-input>-->
					<el-select
							v-model="formViewData.socialUnitName"
							filterable
							remote
							reserve-keyword
							placeholder="请输入关键词"
							:remote-method="remoteMethod"
							@change="socialChange"
							:loading="loading">
						<el-option
								v-for="(item, index) in optionsSocial"
								:key="index"
								:label="item.UnitName"
								:value="index">
						</el-option>
					</el-select>
				</el-form-item>
<!--				<el-form-item label="单位地址：" prop="address">-->
<!--					<el-input v-model="formViewData.address"  readonly style="width: 90%" placeholder="请输入单位地址" clearable @clear='handleClear'></el-input>-->
<!--				</el-form-item>-->
				<el-form-item label="岗位：" prop="stationId">
<!--					<el-input v-model="formViewData.studentName"  style="width: 80%" placeholder="请输入岗位" clearable @clear='handleClear'></el-input>-->
					<el-select v-model="formViewData.stationId" @change="changeStationVal" placeholder="请选择岗位" clearable>
						<el-option
								v-for="item in stationArr"
								:key="item.Id"
								:label="item.Name"
								:value="item.Id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="isTradeDialog" label="行业：" prop="stationId">
					<el-select v-model="formViewData.tradeId"  placeholder="请选择行业" clearable>
						<el-option
								v-for="item in tradeList"
								:key="item.Id"
								:label="item.Name"
								:value="item.Id">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="addStudentDialog = false">取 消</el-button>
				<el-button type="primary" @click="confirmAddStudent">确 定</el-button>
			</div>
		</el-dialog>
<!--	关联已报名学员	-->
		<el-dialog
				title="关联已报名学员"
				:visible.sync="linkStudentDialog"
				v-model="linkStudentDialog"
				:close-on-click-modal="false"
				width="70%"
		>
			<el-form :inline="true" label-width="" style="margin-top: 20px">
				<el-form-item label="关键字：">
					<el-input v-model="keyWordLink" style="width: 220px" placeholder="请输入学员姓名" clearable @clear='handleClear'></el-input>
				</el-form-item>
				<el-form-item label="">
					<el-button type="primary" @click="getListDataViewStudy">查询</el-button>
				</el-form-item>
			</el-form>
			<el-table
					ref="multipleTable"
					:data="tableDataViewStudy"
					tooltip-effect="dark"
					style="width: 100%"
					@selection-change="handleSelectionChange">
				<el-table-column
						type="selection"
						width="55">
				</el-table-column>
				<el-table-column prop="Name" label="学员姓名" width=""></el-table-column>
				<el-table-column prop="Name" label="手机号" width=""></el-table-column>
				<el-table-column prop="Name" label="工作单位" width=""></el-table-column>
				<el-table-column prop="Name" label="岗位名称" width=""></el-table-column>
				<el-table-column prop="Name" label="行业名称" width=""></el-table-column>
				<el-table-column prop="Name" label="报名时间" width=""></el-table-column>
			</el-table>
			<div style="width: 100%;display: flex;justify-content: space-between;margin-top: 20px">
				<div>
					<el-col  class="" style="width:220px;margin: 0 auto;">
						<el-pagination
								@size-change="handleSizeChangeLink"
								@current-change="handleCurrentChangeLink"
								:current-page="pagesLinkStudy.pageIndex"
								:page-sizes="pagesLinkStudy.pageArr"
								:page-size="pagesLinkStudy.pageSize"
								layout="total, sizes, prev, pager, next, jumper"
								:total="pagesLinkStudy.dataCount">
						</el-pagination>
					</el-col>
				</div>
				<div>
					<template>
						<span style="color: red">说明：本项目已报名学习未缴费的学员名单</span>
					</template>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="linkStudentDialog = false">取 消</el-button>
				<el-button type="primary" @click="confirmLinkStudent">保 存</el-button>
			</div>
		</el-dialog>
<!--	导入送培学员名单	-->
		<el-dialog
				title="导入送培学员名单"
				:visible.sync="importStudentDialog"
				v-model="importStudentDialog"
				:close-on-click-modal="false"
				width="70%"
		>
			<el-form :inline="true" label-width="" style="margin-top: 20px">
				<el-form-item label="导入送培学员名单：">
					<input
							type="file"
							@change="handleFileImport"
							style="width: 80%"
							ref="fileImport"
					/>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="importStudentDialog = false">取 消</el-button>
				<el-button type="primary" @click="handleUpload">保 存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import {
	getDeletedStaffsAuthoritySetting,
	getLearnRecordListPage,
	getDataDictionaryList,
	uploadTempFile,
	saveProtocolSocialUnit,
	getNoPayedAccountList,
	getProtocolSocialUnitList,
	getProtocolStaffList,
	getSocialUnitListByKeyWord,
	addSocialUnitChaosByApp,
	addProtocolStaff,
	relateProtocol,
	changeProtocolStaff,
	delRelateProtocol,
	importSocialUnitChaos,
	deleteProtocolSocialUnit,
	getStaffForPDF, muban
} from '../../api/api'
import util from "../../../util/date";
import axios from "axios";
import Qs from 'qs';
import api from "@/api";
export default {
	components: {Toolbar},
	data() {
		return {
			textDialog:false,
			importStudentDialog:false,
			multipleSelection:undefined,
			tableDataViewStudy:undefined,
			linkStudentDialog:false,
			optionsSocial:[],
			loading:false,
			formViewData:{
				Id:undefined,
				phoneNumber:undefined,//手机号
				name:undefined,//姓名
				stationId:undefined,//培训项目
				code:undefined,//培训项目编码
				protocolPrice:undefined,//报名时协议单价，如果协议人数已满，采用教材中设置的协议费用
				cardNo:undefined,//身份证号
				protocolId:undefined,//协议Id
				projectId	:undefined,//项目Id
				socialUnitId:undefined,//
				socialUnitName:undefined,//
				Address:undefined,//单位地址
				tradeId:0,//行业
			},
			formViewDataRules:{
				phoneNumber:[
						{ required: true, message: '请输入手机号', trigger: 'blur' },
						{ min: 11, max: 11, message: '请输入11位手机号', trigger: 'blur' }
				],
				name:[
						{ required: true, message: '请输入姓名', trigger: 'blur' },
				],
				stationId:[
						{ required: true, message: '请选择培训项目', trigger: 'change' },
				],
			},
			stationArr:[],
			previewDialog:false,
			payMethod:[
					{
						value: 0,
						label: '银行汇款'
					},
					{
						value: 1,
						label: '微信支付宝转账'
					},
			],
			settlePeriod :[
					{
						value: 1,
						label: '一月'
					},
					{
						value: 2,
						label: '二月'
					},
					{
						value: 3,
						label: '三月'
					},
					{
						value: 4,
						label: '四月'
					},
					{
						value: 5,
						label: '五月'
					},
					{
						value: 6,
						label: '六月'
					},
					{
						value: 7,
						label: '七月'
					},
					{
						value: 8,
						label: '八月'
					},
					{
						value: 9,
						label: '九月'
					},
					{
						value: 10,
						label: '十月'
					},
					{
						value: 11,
						label: '十一月'
					},
					{
						value: 12,
						label: '十二月'
					}
			],
			settleMethod: [
				{
					value: 0,
					label: '按期支付'
				},
				{
					value: 1,
					label: '取证支付'
				}
			],
			protocolPayMethod: [
				{
					value: 0,
					label: '单位协议缴费'
				},
				{
					value: 1,
					label: '先付款后送培'
				}
			],
			formData: {
				dubboProtocolListReqStaff:undefined,//送培学员名单
				socialUnitName:undefined,//单位名称
				socialCreditNumber:undefined,//单位社会统一信用代码
				socialUnitId:undefined,//单位id
				bankName:undefined,//开户行
				bankCardNumber:undefined,//银行卡号
				address:undefined,//注册地址
				phoneNumber:undefined,//电话
				contactPerson:undefined,//客户联系人
				contactPersonPhoneNumber:undefined,//客户联系人电话
				protocolPayMethod:undefined,//"0:单位协议缴费；1：先付款后送培"
				protocolUrl:undefined,//协议文本地址
				settleMethod:undefined,//"0:按期支付，1：取证支付"
				settlePeriod:undefined,//"如果为按期支付，这里表示结算周期，每X月结算一次"
				payMethod:undefined,//"0:银行汇款；1：微信支付宝转账"
				protocolEffectDate:undefined,//合同生效开始时间，无截止时间
				planPayDate:undefined,//约定付款时间
				protocolInCharge:undefined,//"协议责任人，即催收责任人"
				dubboProtocolConfigReqStr:[],//"协议配置"
				id:undefined,
			},
			formDataRules: {
				socialUnitName: {required: true, message: '请输入单位名称', trigger: 'blur'},
				socialCreditNumber: {required: true, message: '请输入社会统一信用代码', trigger: 'blur'},
				// bankName: {required: true, message: '请输入开户行', trigger: 'blur'},
				// bankCardNumber: {required: true, message: '请输入银行卡号', trigger: 'blur'},
				// address: {required: true, message: '请输入注册地址', trigger: 'blur'},
				protocolPayMethod: {required: true, message: '请选择协议缴费方式', trigger: 'blur'},
				protocolUrl: {required: true, message: '请上传协议文本', trigger: 'blur'},
				settleMethod: {required: true, message: '请选择结算方式', trigger: 'blur'},
				settlePeriod: {required: true, message: '请选择结算周期', trigger: 'blur'},
				payMethod: {required: true, message: '请选择付款方式', trigger: 'blur'},
				protocolEffectDate: {required: true, message: '请选择协议生效时间', trigger: 'blur'},
				planPayDate: {required: true, message: '请选择约定付款时间', trigger: 'blur'},
				protocolInCharge: {required: true, message: '请选择协议责任人', trigger: 'blur'},
				dubboProtocolConfigReqStr: {required: true, message: '请选择协议配置', trigger: 'blur'},
				contactPerson: {required: true, message: '请输入联系人', trigger: 'blur'},
				contactPersonPhoneNumber: {required: true, message: '请输入联系人电话', trigger: 'blur'},
				// phoneNumber: {required: true, message: '请输入手机号', trigger: 'blur'},
				
			},
			form: [],
			activeName: '0',
			addOrEditDialog:false,
			isEdit:false,
			pages : {
				pageSize: 20,//默认每页条数
				pageArr: [10, 20, 30, 40],//默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			pagesView : {
				pageSize: 20,//默认每页条数
				pageArr: [10, 20, 30, 40],//默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			pagesLinkStudy : {
				pageSize: 20,//默认每页条数
				pageArr: [10, 20, 30, 40],//默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			viewDialog : false,
			detailNeedData:undefined,
			filters: {
				keyWord:undefined,//搜索关键字
				protocolPayMethod:undefined,
			},
			stationList:[],
			tradeList:[],
			options3: [
				{value:1,name:'已启用'},
				{value:0,name:'未启用'},
			],
			options: [
				{value:1,name:'先付款后送培'},
				{value:0,name:'单位协议缴费'},
			],
			buttonList: [],
			uploadImagesUrl:undefined,
			fileList:[],
			tableData: [],
			tableDataStudy: [],
			tableDataQuestion: [],
			tableDataSimulation: [],
			listLoading: false,
			listStudyLoading: false,
			listQuestionLoading: false,
			listSimulationLoading: false,
			needData:undefined,
			formList:{},
			fileData:undefined,
			fileDataImport:undefined,
			needDataView:undefined,
			tableDataView:undefined,
			listViewLoading:false,
			addStudentDialog:false,
			keywordStudy:undefined,
			keyWordLink:undefined,
			isAdd:false,
			isTradeDialog:false,
		}
	},
	methods: {
		changeStationVal(val){
			console.log(11111)
			const arr = this.stationArr.find(item => item.Id === val)
			console.log(arr,'arrr')
			this.isTradeDialog = !!arr.IsRelation;
		},
		handleUploadView(){
			window.open(this.needData.ProtocolUrl)
		},
		async handleClickView() {
	    let params = {
	        protocolId: this.needData.Id,
	        // ProjectId: window.localStorage.getItem('projectId')
	    };
	    // const res = await getStaffForPDF(params);
			getStaffForPDF(params).then((value,res)=>{
				console.log(value,'hesaaaaa')
				window.open(value.request.responseURL)
			})
			
		},
		socialChangeValue(val){
			const value = this.optionsSocial[val]
			console.log(value,'value')
			this.formData.socialCreditNumber = value.SocialCreditNumber
			this.formData.socialUnitName = value.UnitName
			// if (value){
			// 	if (!value.Id){
			// 		let params = {
			// 			...value,
			// 		}
			// 		addSocialUnitChaosByApp(params).then((res)=>{
			// 			if (res.data.Success){
			// 				console.log(res.data.Response,'danwei11111')
			// 				this.formViewData.socialUnitId = res.data.Response
			// 				this.formViewData.address = value.Address
			// 			}
			// 		})
			// 	}else{
			// 		this.formViewData.socialUnitId = value.Id
			// 		this.formViewData.address = value.Address
			// 	}
			// }
		},
		socialChange(val){
			const value = this.optionsSocial[val]
			this.formViewData.socialUnitId = value.Id
			if (value){
				if (!value.Id){
					let params = {
						...value,
					}
					addSocialUnitChaosByApp(params).then((res)=>{
						if (res.data.Success){
							console.log(res.data.Response,'danwei11111')
							this.formViewData.socialUnitId = res.data.Response
							this.formViewData.address = value.Address
						}
					})
				}else{
					this.formViewData.socialUnitId = value.Id
					this.formViewData.address = value.Address
				}
			}
		},
		//社会单位
		remoteMethod(query) {
			if (query !== '') {
				this.loading = true;
				let params = {
					keyWord:query,
				}
				getSocialUnitListByKeyWord(params).then((res)=>{
					this.loading = false ;
					if (res.data.Success){
						this.optionsSocial = res.data.Response
					}
				})
				// setTimeout(() => {
				// 	this.loading = false;
				// 	this.optionsSocial = this.list.filter(item => {
				// 		return item.label.toLowerCase()
				// 				.indexOf(query.toLowerCase()) > -1;
				// 	});
				// }, 200);
			} else {
				this.options = [];
			}
		},
		//解绑
		handleCancleClick(row){
			let params = {
				Id:row.Id,
				SocialUnitId:row.SocialUnitId,
				ProtocolId:this.needData.Id,
			}
			this.$confirm('确定要解绑该学员吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				delRelateProtocol(params).then((res)=>{
					if (res.data.Success){
						this.addStudentDialog = false
						this.$message({
							message:res.data.Message,
							type:'success'
						})
						this.getListDataView()
					} else {
						this.$message({
							message:res.data.Message,
							type:'warning'
						})
					}
				})
			});
		},
		//变更学员
		handleEditClick(row){
			this.isAdd = false;
			this.formViewData ={
				Id:row.Id,
				phoneNumber:row.PhoneNumber,//手机号
				name:row.Name,//姓名
				stationId:row.StationId,//培训项目
				code:undefined,//培训项目编码
				protocolPrice:row.ProtocolPrice,//报名时协议单价，如果协议人数已满，采用教材中设置的协议费用
				cardNo:row.CardNo,//身份证号
				protocolId:this.needData.Id,//协议Id
				projectId	:undefined,//项目Id
				socialUnitId:row.SocialUnitId,//
				socialUnitName:row.SocialUnitName,//
				address:row.Address,//单位地址
				tradeId:row.TradeId,//行业id
			}
			this.addStudentDialog = true;
		},
		clearFileInput() {
			this.$refs.fileInput.value = ''; // 清空 input 的值
			this.$refs.fileImport.value = ''; // 清空 input 的值
		},
		//添加学员信息
		confirmAddStudent(){
			// this.formViewData.protocolCode = this.needDataView.ProtocolCode
			// console.log(this.needDataView,'jjjjjjjj')
			this.$refs.formViewData.validate((valid) => {
				if (valid) {
					let params = {
						Id:undefined,
						PhoneNumber:this.formViewData.phoneNumber,
						Name:this.formViewData.name,
						StationId:this.formViewData.stationId,
						Code:this.formViewData.code,
						ProtocolPrice:this.formViewData.protocolPrice,
						CardNo:this.formViewData.cardNo,
						ProtocolId:this.needDataView.Id,//协议id
						SocialUnitId:this.formViewData.socialUnitId,
						OriginProtocolStaffId:this.formViewData.Id,
						TradeId:this.formViewData.tradeId,
						// ...this.formViewData,
					}
					if(this.isAdd){
						addProtocolStaff(params).then((res)=>{
							if (res.data.Success){
								this.addStudentDialog = false
								this.$message({
									message:res.data.Message,
									type:'success'
								})
								this.getListDataView()
							} else {
								this.$message({
									message:res.data.Message,
									type:'warning'
								})
							}
						})
					} else {
						changeProtocolStaff(params).then((res)=>{
							if (res.data.Success){
								this.addStudentDialog = false
								this.$message({
									message:res.data.Message,
									type:'success'
								})
								this.getListDataView()
							} else {
								this.$message({
									message:res.data.Message,
									type:'warning'
								})
							}
						})
					}
				}
			});
			
		},
		//获取岗位列表
		getStationList() {
			let params = {
				dataType: 1,
			}
			getDataDictionaryList(params).then((res) => {
				if(res.data.Success){
					this.stationArr = res.data.Response
				}else{

				}
			})
		},
		//获取岗位列表
		getTradeList() {
			let params = {
				dataType: 0,
			}
			getDataDictionaryList(params).then((res) => {
				if(res.data.Success){
					this.tradeList = res.data.Response.filter(item=>item.IsEnable === true)
				}else{

				}
			})
		},
		//学员列表
		preview(row){
			this.needDataView = row
			this.getDataListView()
			this.previewDialog = true ;
		},
		handleFileUpload(event) {
	    const file = event.target.files[0]; // 获取用户选择的第一个文件
	    if (file) {
	        this.fileData = file; // 将文件对象赋值给 formData 的某个字段
	        console.log('File uploaded:', file); // 打印文件信息
	    }
		},
		handleFileImport(event) {
			const file = event.target.files[0]; // 获取用户选择的第一个文件
			if (file) {
				this.fileDataImport = file; // 将文件对象赋值给 formData 的某个字段
				console.log('File uploaded:', file); // 打印文件信息
			}
		},
		getToken() {
			return localStorage.getItem("Token")
		},
		// 上传之前函数
		beforeUpload(file) {
			const isLt2M = file.size / 1024 / 1024 > 2;
			var type = file.name.substring(file.name.lastIndexOf(".")+1);
			// if (type === 'jpg' || type === 'png') {} else {
			// 	this.$message.error('上传图片只能是 JPG/PNG 格式!');
			// 	return false;
			// }
			if (!isLt2M === false) {
				this.$message.error('上传大小不能超过 2MB!');
				return false
			}
		},
		// 图片上传成功_营业执照
		handle_success1(res) {
			if(res.Success) {
				this.$message.success('上传成功')
				this.fileList = []
				this.fileList.push(res.Response)
				console.log(this.fileList,'fileList')
				this.formData.protocolUrl = res.Response.webupurl
			}else{
				this.$message.error(res.Message)
			}
		},
		async confirmAdd() {
			this.$refs.formData.validate(valid => {
				if (valid){
					// 提取过滤条件为独立的函数
					const isValidItem = (item) => item.protocolPrice && item.protocolPersonNum && item.protocolAllowance;
					
					// 赋值并过滤
					this.formData.dubboProtocolConfigReqStr = Object.values(this.formList).filter(isValidItem);

					setTimeout(async () => {
						this.formData.dubboProtocolConfigReqStr = JSON.stringify(this.formData.dubboProtocolConfigReqStr);
						this.formData.file = this.fileData
						this.formData.projectId =parseInt(window.localStorage.getItem('projectId'))
						// 使用 FormData 对象发送文件
						const formData = new FormData();
						for (const key in this.formData) {
							if (this.formData[key] !== undefined) {
								formData.append(key, this.formData[key]);
							}
						}
						
						// formData.dubboProtocolConfigReqStr = Object.values(this.formList);
						console.log(formData,'this.formdata')
						
						saveProtocolSocialUnit(formData).then(res => {
							if(res.data.Success) {
								this.$message.success('保存成功')
								this.getListData()
								this.addOrEditDialog = false;
							}else{
								this.$message.error(res.data.Message)
							}
							this.clearFileInput()
						})
					},100)
				}
			})
			
		
		},
		async getExamList() {
			let params = {
				projectId:window.localStorage.getItem('projectId'),
				dataType:1,
			}
			this.formList = {}
			const res = await getDataDictionaryList(params)
				if (res.data.Success){
					console.log(res.data.Response,'diclistttttt')
					const list = res.data.Response
					if (list&&list.length>0){
						this.form = list
						list.forEach(item=>{
							this.$set(this.formList,item.Id,{
								stationId: item.Id,//培训项目
								stationName: item.Name,//培训项目
								protocolSocialUnitId:undefined,//协议ID
								protocolPrice: undefined,//协议单价
								protocolPersonNum: undefined,//协议人数
								protocolAllowance: undefined,//招生提成/人（分）
							});
						})
					}
				}
			
		},
		formatSeconds  (seconds)  {
			const hours = Math.floor(seconds / 3600);
			const minutes = Math.floor((seconds % 3600) / 60);
			const remainingSeconds = seconds % 60;
			return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
		},
		callFunction(item) {
			this[item.Func].apply(this, item);
		},
		// 清除input框，重新获取数据
		handleClear(){
			this.getDataList()
		},
		// 获取分页列表
		getDataList() {
			this.listLoading = true
			let params = {
				pageIndex:this.pages.pageIndex,//当前页
				pageSize:this.pages.pageSize,//每页条数
				keyWord:this.filters.keyWord,//搜索关键字
				protocolPayMethod:this.filters.protocolPayMethod,//协议缴费方式0:单位协议缴费；1：先付款后送培
			}
			getProtocolSocialUnitList(params).then((res) => {
				let result = res.data
				if(result.Success){
					this.tableData = result.Response.Data
					this.listLoading = false;
				}else{
					this.listLoading = false;
				}
			})
		},
		// 查询
		getListData() {
			this.pages.pageIndex = 1;
			this.pages.pageSize = 20;
			this.getDataList();
		},
		//新增
		async handleAdd(){
			this.isEdit = false;
			this.formData = {
				dubboProtocolListReqStaff:undefined,//送培学员名单
				socialUnitName:undefined,//单位名称
				socialCreditNumber:undefined,//单位社会统一信用代码
				socialUnitId:undefined,//单位id
				bankName:undefined,//开户行
				bankCardNumber:undefined,//银行卡号
				address:undefined,//注册地址
				phoneNumber:undefined,//电话
				contactPerson:undefined,//客户联系人
				contactPersonPhoneNumber:undefined,//客户联系人电话
				protocolPayMethod:undefined,//"0:单位协议缴费；1：先付款后送培"
				protocolUrl:undefined,//协议文本地址
				settleMethod:undefined,//"0:按期支付，1：取证支付"
				settlePeriod:undefined,//"如果为按期支付，这里表示结算周期，每X月结算一次"
				payMethod:undefined,//"0:银行汇款；1：微信支付宝转账"
				protocolEffectDate:undefined,//合同生效开始时间，无截止时间
				planPayDate:undefined,//约定付款时间
				protocolInCharge:undefined,//"协议责任人，即催收责任人"
				dubboProtocolConfigReqStr:[],//"协议配置"
			}
			await this.getExamList()
			this.addOrEditDialog = true;
				console.log(this.formData.dubboProtocolConfigReqStr,'this.formData.dubboProtocolConfigReqStr')
		},
		//编辑
		async handleEdit(){
			if (this.needData){
				await this.getExamList()
				this.formData= {
					dubboProtocolListReqStaff:undefined,//送培学员名单
					socialUnitName:this.needData.UnitName,//单位名称
					socialCreditNumber:this.needData.SocialCreditNumber,//单位社会统一信用代码
					socialUnitId:this.needData.SocialUnitId,//单位id
					bankName:this.needData.BankName,//开户行
					bankCardNumber:this.needData.BankCardNumber,//银行卡号
					address:this.needData.Address,//注册地址
					phoneNumber:this.needData.PhoneNumber,//电话
					contactPerson:this.needData.ContactPerson,//客户联系人
					contactPersonPhoneNumber:this.needData.ContactPersonPhoneNumber,//客户联系人电话
					protocolPayMethod:this.needData.ProtocolPayMethod,//"0:单位协议缴费；1：先付款后送培"
					protocolUrl:this.needData.ProtocolUrl,//协议文本地址
					settleMethod:this.needData.SettleMethod,//"0:按期支付，1：取证支付"
					settlePeriod:this.needData.SettlePeriod,//"如果为按期支付，这里表示结算周期，每X月结算一次"
					payMethod:this.needData.PayMethod,//"0:银行汇款；1：微信支付宝转账"
					protocolEffectDate:this.needData.ProtocolEffectDate,//合同生效开始时间，无截止时间
					planPayDate:this.needData.PlanPayDate,//约定付款时间
					protocolInCharge:this.needData.ProtocolInCharge,//"协议责任人，即催收责任人"
					dubboProtocolConfigReqStr:[],//"协议配置"
					id:this.needData.Id,
				}
				await this.needData.DubboProtocolConfigResList.forEach(item=>{
					// this.$set(this.formList,item.Id,{
					// 	stationId: item.StationId,//培训项目
					// 	stationName: item.StationName,//培训项目
					// 	protocolSocialUnitId:undefined,//协议ID
					// 	protocolPrice: item.ProtocolPrice,//协议单价
					// 	protocolPersonNum: item.ProtocolPersonNum,//协议人数
					// 	protocolAllowance: item.ProtocolAllowance,//招生提成/人（分）
					// });
					this.$set(this.formList[item.StationId], 'stationId', item.StationId);
					// this.$set(this.formList[item.StationId], 'stationName', item.StationName);
					this.$set(this.formList[item.StationId], 'protocolPrice', item.ProtocolPrice);
					this.$set(this.formList[item.StationId], 'protocolPersonNum', item.ProtocolPersonNum);
					this.$set(this.formList[item.StationId], 'protocolAllowance', item.ProtocolAllowance);
					// this.$set(this.formList[item.Id], 'stationId', item.StationId);
				})
				// setTimeout(()=>{
					this.isEdit = true;
					this.addOrEditDialog = true;
				// },5000)
				
			} else {
				this.$message({
					message: '请先选择一条数据！',
					type: 'warning',
				})
			}
		},
		// 删除
		handleDel(){
			this.$confirm('确定删除该条数据吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let params = {
					Id: undefined,
					SocialUnitId: this.needData.SocialUnitId,
					ProtocolId: this.needData.Id,
					ProjectId: window.localStorage.getItem('projectId')
				}
				deleteProtocolSocialUnit(params).then((res) => {
					if (res.data.Success) {
						this.$message({
							message: '删除成功！',
							type: 'success',
						});
						this.getDataList();
					} else {
						this.$message({
							message: res.data.Message,
							type: 'warning',
						});
					}
				})
			});
		},
		// 点击table某一行
		selectCurrentRow(val) {
			this.needData = val
		},
		// 分页
		handleCurrentChange(val) {
			this.pages.pageIndex = val;
			this.getDataList();
		},
		handleSizeChange(val){
			this.pages.pageSize = val;
			this.getDataList();
		},
		// 分页
		handleCurrentChangeStudy(val) {
			this.pagesView.pageIndex = val;
			this.getDataListView()
		},
		handleSizeChangeStudy(val){
			this.pagesView.pageSize = val;
			this.getDataListView()
		},
		// 分页
		handleCurrentChangeLink(val) {
			this.pagesLinkStudy.pageIndex = val;
			
		},
		handleSizeChangeLink(val){
			this.pagesLinkStudy.pageSize = val;
			
		},
		// 获取学员分页列表
		getDataListView() {
			this.listViewLoading = true
			let params = {
				pageIndex:this.pagesView.pageIndex,//当前页
				pageSize:this.pagesView.pageSize,//每页条数
				keyWord:this.keywordStudy,//搜索关键字
				protocolId:this.needDataView.Id,
			}
			getProtocolStaffList(params).then((res) => {
				let result = res.data
				if(result.Success){
					this.tableDataView = result.Response.Data
					this.listViewLoading = false;
				}else{
					this.listViewLoading = false;
				}
			})
		},
		// 查询学员列表
		getListDataView() {
			this.pagesView.pageIndex = 1;
			this.pagesView.pageSize = 20;
			this.getDataListView();
		},
		//新增学员
		async handleAddView(){
			this.formViewData={
				Id:undefined,
				phoneNumber:undefined,//手机号
				name:undefined,//姓名
				stationId:undefined,//培训项目
				code:undefined,//培训项目编码
				protocolPrice:undefined,//报名时协议单价，如果协议人数已满，采用教材中设置的协议费用
				cardNo:undefined,//身份证号
				protocolId:undefined,//协议Id
				projectId	:undefined,//项目Id
				socialUnitId:undefined,//
				socialUnitName:undefined,//
				Address:undefined,//单位地址
			}
			this.getStationList()
			this.getTradeList()
			this.isAdd = true
			this.addStudentDialog = true;
		},
		//关联已报名学员
		handleEditView(){
			if (this.needDataView){
				this.getDataListViewStudy()
				this.linkStudentDialog = true;
			} else {
				this.$message({
					message:'请先选择一条数据！',
					type:'warning'
				})
			}
		},
		// 导入送培学员名单
		handleExport(){
			this.importStudentDialog = true;
		},
		// 送培学员名单上传
		handleUpload(){
			let params = {
				Id:this.needData.Id,//协议ID
				File:this.fileDataImport,//文件
				ProjectId:window.localStorage.getItem('projectId')
			}
			const formData = new FormData();
			for (const key in params) {
				if (params[key] !== undefined) {
					formData.append(key, params[key]);
				}
			}
			importSocialUnitChaos(formData).then((res) => {
				if (res.data.Success){
					this.$message({
						message:'导入成功！',
						type:'success'
					})
					this.importStudentDialog = true;
					this.clearFileInput()
				}	else {
					this.$message({
						message:res.data.Message,
						type:'warning'
					})
					this.clearFileInput()
				}
			})
		},
		// 送培学员名单下载
		handleDownload(){
			let params = {
			}
			muban(params).then((req,res) => {
				window.open(req.request.responseURL)
			})
		},
		
		//关联已报名学员列表
		async getDataListViewStudy() {
			let params = {
				pageIndex:this.pagesLinkStudy.pageIndex,//当前页
				pageSize:this.pagesLinkStudy.pageSize,//每页条数
				keyWord:this.keyWordLink,//搜索关键字
				projectId:this.needDataView.Id,//协议ID
			}
			const res = await getNoPayedAccountList(params)
			if (res.data.Success){
				this.tableDataViewStudy = res.data.Response.Data
			}
		},
		getListDataViewStudy() {
			this.pagesLinkStudy.pageIndex = 1;
			this.pagesLinkStudy.pageSize = 20;
			this.getDataListViewStudy();
		},
		handleSelectionChange(val) {
			console.log(val,'multipleSelection')
			this.multipleSelection = val;
		},
		//关联已报名学员保存
		confirmLinkStudent(){
			if (this.multipleSelection&&this.multipleSelection.length===0){
				this.$message({
					message:'请选择要关联的学员！',
					type:'warning'
				})
				return
			}
			let arr = []
			arr = this.multipleSelection.map(item=>item.Id)
			let params = {
				ProtocolId:this.needData.Id,//协议ID
				Ids:arr,//
			}
			relateProtocol(params).then(res=>{
				if(res.data.Success){
					this.$message({
						message:res.data.Message,
						type:'success'
					})
					this.linkStudentDialog = false;
					this.getDataListViewStudy()
				} else {
					this.$message({
						message:res.data.Message,
						type:'warning'
					})
				}
			})
		},
		//时间格式化
		formatCreateTime: function(row, column) {
			return !row.CreateTime || row.CreateTime == ""
					? ""
					: util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
		},
		// 表格数据分页条数累计
		indexMethod(index) {
			let currentPage = this.pages.pageIndex
			let pageSize = this.pages.pageSize
			return (index + 1) + (currentPage - 1) * pageSize
		},
	},
	watch: {
	
	},
	computed: {
		headers() {
			return{
				"Authorization": 'Bearer ' + this.getToken() // 直接从本地获取token就行
			}
		}
	},
	mounted() {
		let routers = window.localStorage.router
				? JSON.parse(window.localStorage.router)
				: [];
		this.buttonList = getButtonList(this.$route.path, routers);
		this.getListData();
		this.uploadImagesUrl = api.uploadTempFile
	},
}
</script>

<style lang="stylus" scoped>
.el-textarea>>>.el-textarea__inner {
	min-height 100px!important;
}
</style>
<style>
.el-tooltip__popper {
	max-width: 500px;
}
</style>